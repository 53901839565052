document.addEventListener("DOMContentLoaded", function () {
    let from = '';
    let  to = '';
    from = document.getElementById('anchor');
    to = document.getElementById('goto');
    from.addEventListener('click', function (event) {
        console.log('clicked')
        to.scrollIntoView({behavior: "smooth"});
    })
});


// (function ($) { 'use strict';

//     $(function () {
        
//     });

// }(jQuery));