(function ($) {
  "use strict";

  $(function () {
    let objMindfulness = $("#mindfulness");
    let objSound = $("#sound");
    let objTouch = $("#touch");

    // let popupMindfulness = $("#popup_mindfulness");
    // let popupSound = $("#popup_sound");
    // let popupTouch = $("#popup_touch");

    let vennBase = $(".-base");
    let vennBaseLabels = $(".-base-labels");
    let vennMindfulness = $(".-mindfulness");
    let vennSound = $(".-sound");
    let vennTouch = $(".-touch");

    objMindfulness.on("click", function (e) {
      vennBase.toggleClass("hide");
      vennBaseLabels.toggleClass("hide");
      vennMindfulness.toggleClass("hide");
    });
    vennMindfulness.on("click", function (e) {
      vennBase.toggleClass("hide");
      vennBaseLabels.toggleClass("hide");
      vennMindfulness.toggleClass("hide");
    });

    objSound.on("click", function (e) {
      vennBase.toggleClass("hide");
      vennBaseLabels.toggleClass("hide");
      vennSound.toggleClass("hide");
    });
    vennSound.on("click", function (e) {
      vennBase.toggleClass("hide");
      vennBaseLabels.toggleClass("hide");
      vennSound.toggleClass("hide");
    });

    objTouch.on("click", function (e) {
      vennBase.toggleClass("hide");
      vennBaseLabels.toggleClass("hide");
      vennTouch.toggleClass("hide");
    });
    vennTouch.on("click", function (e) {
      vennBase.toggleClass("hide");
      vennBaseLabels.toggleClass("hide");
      vennTouch.toggleClass("hide");
    });
  });
})(jQuery);
