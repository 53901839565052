console.log("facet js");
(function ($) {
  "use strict";

  $(function () {
    const facetContainer = $("#facet-container");
    //const facetTop = $("#facet-top");
    const facetMiddle = $("#facet-middle");
    const facetBottom = $("#facet-bottom");
    console.log(facetContainer.height());

    if (facetContainer.height() <= 500) {
      facetMiddle.css("display", "none");
      facetBottom.css("display", "none");
    } else if (
      facetContainer.height() > 500 &&
      facetContainer.height() < 2000
    ) {
      facetMiddle.css("display", "block");
      facetBottom.css("display", "none");
    }
  });
})(jQuery);
